<template>
    <b-modal v-model="openedModal" centered hide-footer>
        <template #modal-title>
            <h5 v-if="type === 'add'">Dodaj</h5>
            <h5 v-else>Uredi</h5>
        </template>
        <validation-observer ref="validate">
            <b-form>
                <b-form-group label="Ime">
                    <validation-provider #default="{ errors }" name="ime" rules="required">
                        <b-form-input v-model="user.name" placeholder="Ime"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Priimek">
                    <validation-provider #default="{ errors }" name="priimek" rules="required">
                        <b-form-input v-model="user.surname" placeholder="Priimek"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Email">
                    <validation-provider #default="{ errors }" name="email" rules="required|email">
                        <b-form-input v-model="user.email" placeholder="Email"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Pravice">
                    <validation-provider #default="{ errors }" name="pravice" rules="required">
                        <v-select :options="roles" label="title" v-model="user.permission_group_id" :reduce="ele => ele.id" style="height: 38px;" placeholder="Izberito pravico"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Geslo" v-if="type === 'add'">
                    <validation-provider #default="{ errors }" name="geslo" rules="required">
                        <b-input-group class="input-group-merge">
                            <b-form-input id="login-password" v-model="user.password" class="form-control-merge" :type="passwordFieldType" placeholder="Geslo"/>
                            <b-input-group-append is-text>
                                <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"  @click="togglePasswordVisibility"/>
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Geslo" v-else>
                    <b-input-group class="input-group-merge">
                        <b-form-input id="login-password" v-model="user.password" class="form-control-merge" :type="passwordFieldType" placeholder="Geslo"/>
                        <b-input-group-append is-text>
                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"  @click="togglePasswordVisibility"/>
                        </b-input-group-append>
                    </b-input-group>
                    <b-alert class="mt-1" show variant="warning">Če je polje za geslo prazno, bo geslo ostalo nespremenjeno.</b-alert>
                </b-form-group>
                <b-form-group v-if="type === 'add'" class="text-right">
                    <b-button variant="secondary" @click="validationFormAdd">DODAJ</b-button>
                </b-form-group>
                <b-form-group v-else-if="type === 'edit'" class="text-right">
                    <b-button variant="secondary" @click="validationFormEdit">UREDI</b-button>
                </b-form-group>
            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, PhoneNumber } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import {BAlert, BFormGroup, BFormInput, BInputGroup, BModal, BInputGroupAppend, BButton, BForm} from 'bootstrap-vue'
    import VSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'

    export default {
        props: {
            type: {
                type: String,
                reqired: true
            },
            user: {
                type: Object,
                reqired: true
            },
            admin: {
                type: Boolean,
                default: false
            }
        },
        components: {
            VSelect,
            ValidationProvider,
            ValidationObserver,
            BFormGroup,
            BFormInput,
            BInputGroup,
            BAlert,
            BModal,
            BInputGroupAppend,
            BButton,
            BForm
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                openedModal: false,
                text: 'Dodaj',
                required,
                email,
                PhoneNumber,
                roles: []
            }
        },
        methods: {
            async loadData() {
                try {
                    const res = await this.$http.get('/api/management/v1/role')
                    this.roles = res.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                }
            },
            validationFormAdd() {
                
                this.$refs.validate.validate().then(success => {
                    if (success) {
                      
                        this.addUser()
                    }
                })
            },
            validationFormEdit() {
                this.$refs.validate.validate().then(success => {
                    if (success) {
                        this.editUser()
                    }
                })
            },
            openModal() {
                if (this.type === 'edit') {
                    this.text = 'Uredi'
                }
                this.openedModal = true
            },
            addUser() {
                const thisIns = this
                const loadPromise = this.$http.put('/api/management/v1/admin/', this.user)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Admin je dodan')
                    thisIns.openedModal = false
                    thisIns.$emit('success')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            editUser() {
                const thisIns = this
                const loadPromise = this.$http.patch(`/api/management/v1/admin/${this.user.id}`, this.user)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Admin je urejen')
                    thisIns.openedModal = false
                    thisIns.$emit('success')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

